import React from "react"
import Layout from "../components/layout"

const FeedbackPage = () => {
  return (
    <Layout
      seo={{ title: "Feedback" }}
      mainContent={<div>{/* <button onClick={login}>login</button> */}</div>}
    />
  )
}
export default FeedbackPage
